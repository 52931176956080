<template>
  <div class="block">
    <div class="header">
      <!-- <img class="logo" src="/images/logotitle.png" alt /> -->
    </div>
    <div class="traceinfo-management">
      <div class="baseInfo">
        <el-row :gutter="10">
          <el-col :span="8">
            <span class="title">产品名称：</span>
          </el-col>
          <el-col :span="16">
            <span class="value">{{productName}}</span>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <span class="title">产品品牌：</span>
          </el-col>
          <el-col :span="16">
            <span class="value">{{brandName}}</span>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <span class="title">所属公司：</span>
          </el-col>
          <el-col :span="16">
            <span class="value">{{companyName}}</span>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <span class="title">流程名称：</span>
          </el-col>
          <el-col :span="16">
            <span class="value">{{productTraceTitle}}</span>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <span class="title">产品属性：</span>
          </el-col>
          <el-col :span="16">
            <div class="attrs">
              <el-row :gutter="10" v-for="(item,key) in attrArry" :key="key">
                <el-col :span="8">
                  <span class="title">{{item.split(":")[0]}}</span>
                </el-col>
                <el-col :span="16">
                  <span class="value">{{item.split(":")[1]}}</span>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <span class="title">产品图片：</span>
          </el-col>
          <el-col :span="16">
            <el-image style="width: 200px; height: 200px" :src="picurl"></el-image>
          </el-col>
        </el-row>
      </div>

      <el-timeline>
        <el-timeline-item
          v-for="(item,index) in flatterdata"
          :key="index"
          :timestamp="item.name"
          placement="top"
        >
          <el-card>
            <el-row :gutter="10" v-for="(value,key) in item.fieldList" :key="key">
              <div v-if="value.type==='vido_upload'">
                <el-col :span="7">
                  <span class="title">{{value.label}}：</span>
                </el-col>
                <el-col :span="14">
                  <div class="videoWapper">
                    <video
                      :src="getUrl(value.value)"
                      class="video-avatar"
                      controls="controls"
                    >您的浏览器不支持视频播放</video>
                  </div>
                </el-col>
              </div>
              <div v-else-if="value.type==='vido_text'">
                <el-col :span="7">
                  <span class="title">{{value.label}}：</span>
                </el-col>
                <el-col :span="14">
                  <el-col :span="14">
                    <div class="videoWapper">
                      <video
                        :src="value.value"
                        class="video-avatar"
                        controls="controls"
                      >您的浏览器不支持视频播放</video>
                    </div>
                  </el-col>
                </el-col>
              </div>
              <div v-else-if="value.type==='imge_upload'">
                <el-col :span="7">
                  <span class="title">{{value.label}}：</span>
                </el-col>
                <el-col :span="14">
                  <img :src="getUrl(value.value)" class="imgs" />
                </el-col>
              </div>
              <div v-else-if="value.type==='imge_text'">
                <el-col :span="7">
                  <span class="title">{{value.label}}：</span>
                </el-col>
                <el-col :span="14">
                  <img :src="value.value" class="imgs" />
                </el-col>
              </div>
              <div v-else>
                <el-col :span="7">
                  <span class="title">{{value.label}}：</span>
                </el-col>
                <el-col :span="14">
                  <span class="value">{{value.value}}</span>
                </el-col>
              </div>
            </el-row>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script>
import { getTraceInfo, IMG_PREFIX } from "@/api/lib/api.js";
let _ = require("lodash");
export default {
  data() {
    return {
      productName: "",
      brandName: "",
      companyName: "",
      productTraceTitle: "",
      flatterdata: [],
      attrArry: [],
      picurl: "",
    };
  },
  created() {
    this.getTraceInfo();
  },
  methods: {
    getUrl(path) {
      return IMG_PREFIX + path;
    },
    getTraceInfo() {
      const code = window.location.href.split("=")[1];
      getTraceInfo(code).then((res) => {
        let resJson = JSON.parse(res);
        if (resJson.code === 0) {
          let {
            brandName,
            companyName,
            productName,
            productTraceTitle,
            trace,
            productAttrs,
            productPicUrls,
          } = resJson.data;
          this.brandName = brandName;
          this.companyName = companyName;
          this.productName = productName;
          this.productTraceTitle = productTraceTitle;
          this.attrArry = productAttrs.split(",");
          this.picurl = IMG_PREFIX + productPicUrls;
          console.log(this.picurl);
          this.flatterdata = trace;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.block {
  width: 100%;
  height: 100%;
  .header {
    width: 95%;
    margin: 10px auto 3rem;
    text-align: center;
  }
}
.traceinfo-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  margin: 0 auto;
  .avatarImg {
    vertical-align: middle;
    border-radius: 50%;
  }
  .baseInfo {
    margin-bottom: 2rem;
    span {
      font-size: 16px;
    }
  }
  .imgs {
    width: 180px;
    height: 180px;
  }
  .videoWapper {
    width: 200px;
    height: 200px;
    .video-avatar {
      width: 100%;
    }
  }

  .attrs {
    .title {
      font-size: 11px;
    }
  }
}
</style>
<style lang="scss">
.el-row {
  margin-bottom: 20px;
}
.attrs {
  font-size: 12px;
  background: #eeeeee;
  padding: 0.5rem;
  .el-row {
    .el-col {
      span {
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }
  :last-child {
    margin-bottom: 0;
  }
}
</style>